import React from 'react'

function Copyright() {
    return (
        <div className="container-fluid">
            <div>
            Copyright 2022. All Rights Reserved. <strong><a
            className="text-decoration-none"
            href="http://thecarloans.ca">thecarloans.ca</a></strong>
            </div>
            
        </div>
    )
}

export default Copyright
